import React from 'react';
import { graphql, StaticQuery, Link } from "gatsby";
import styled from 'styled-components';

const PortfolioItemsWrapper = styled.div`
	display: flex;
	margin-left: -1rem;
	margin-right: -1rem;
	padding: 1rem 0;
`

const PortfolioItem = styled.div`
	background-color: #f4f4f4;
	margin: 1rem;
	padding: 1.5rem 1rem 2.5rem;
	width: 33.33%;
`

const PortfolioImageFigure = styled.figure`
	margin: 0 0 1rem 0;
`

const PortfolioItemImage = styled.img`
	height: auto;
	max-width: 100%;
`

const PortfolioItemTitle = styled.h2`
	margin: 0 0 1rem;
`

const PortfolioItems = () => {
	return (
		<StaticQuery query={graphql`
			{
			  allWordpressWpPortfolio{
			    edges{
			      node{
			        id
			        slug
			        title
			        excerpt
			        content
			        featured_media{
			          source_url
			        }
			      }
			    }
			  }
			}
		`} render={props => (
			<PortfolioItemsWrapper>
				{props.allWordpressWpPortfolio.edges.map(portfolioItem => (
					<PortfolioItem key={portfolioItem.node.id}>
						<PortfolioItemTitle>{portfolioItem.node.title}</PortfolioItemTitle>
						<PortfolioImageFigure>
							<PortfolioItemImage src={portfolioItem.node.featured_media.source_url} alt={`Thumbnail`} />
						</PortfolioImageFigure>
						<div dangerouslySetInnerHTML={{__html: portfolioItem.node.excerpt}} />
						<Link to={`/portfolio/${portfolioItem.node.slug}`}>
							Read more
						</Link>
					</PortfolioItem>
				))}
			</PortfolioItemsWrapper>)} />
		)
}

export default PortfolioItems;
